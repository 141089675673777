<template>
	<transition name="toast" @after-leave="removeToast"> 
		<div class="toast" v-if="isMounted" :class="toastType">

			<div class="toast__icon" v-if="toastType=='error'">
				<img src="../assets/times-circle.svg" width="24" height="24">
			</div>
			<div class="toast__icon" v-if="toastType=='copy'">
				<img src="../assets/file-copy-alt (1).svg" width="24" height="24">
			</div>

			<div class="toast__message">{{message}}</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: "Toast",

	props: {
		duration: {
			type: Number,
			default: 3000,
		},
		message: {
			type: String,
			required: true,
		},
		toastType: {
			type: String,
			default: "default",
		}
	},

	data() {
		return {
			isMounted: false,
		};
	},

	computed: {
		borderColor(){
			if (this.toastType == "error") return "#ee6868"
			return "lightgrey"
		}
	},

	mounted() {
		// Animate create
		this.isMounted = true

		// Animate destroy
		setTimeout(() => this.isMounted = false, this.duration)
	},

	destroyed() {
		console.log('toast destroyed !');
	},

	methods: {
		removeToast(){
			this.$destroy()
			this.$el.parentNode.removeChild(this.$el);
		}
	},
};
</script>

<style scoped>
.toast{
	position: fixed;
	bottom: 16px;
	right: 16px;
	left: 16px;
	padding: 16px;
	z-index: 3;
	background: white;
	animation: open-toast .3s ease-in-out;
	
	border: 2px solid #d3d3d3;
	border-radius: 8px;

	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: flex-start;
	align-items: center;

-webkit-box-shadow: 0px 4px 21px 6px rgba(169, 169, 169, 0.45);
-moz-box-shadow: 0px 4px 21px 6px rgba(169, 169, 169, 0.45);
box-shadow: 0px 4px 21px 6px rgba(169, 169, 169, 0.45);
}

.toast.error {
	border-color: #ee6868;
-webkit-box-shadow: 0px 4px 21px 6px rgba(238, 104, 104, 0.55);
-moz-box-shadow: 0px 4px 21px 6px rgba(238, 104, 104, 0.55);
box-shadow: 0px 4px 21px 6px rgba(238, 104, 104, 0.55);
}

.toast__icon{
	color: lightgrey;
	height: 24px;
}

.toast__message{
	display: flex;
	justify-content: center;
}

.toast-enter-active {
	animation: toast .3s ease-in-out;
}
.toast-leave-active {
	animation: toast .2s linear reverse;
}

@keyframes toast {
	from {
		bottom: 0;
		opacity: 0;
	}
	to {
		bottom: 16px;
		opacity: 1;
	}
}


</style>