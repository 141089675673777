<template>
    <div class="menu_calendar_settings">
        <div class="menu">

            <!-- Create calendar message -->

            <div class="created-message" v-if="isNewCalendar">
                <div class="created-message__text">
                    Congratulations! You have created <u>{{getVisibilityTitle()}}</u>
                    calendar <b>{{calendarTitle}}</b>. Use it immediately or change following settings.
                </div>

                <BaseButton title="Go to Calendar Page" @click="goToCalendar"/>
                <BaseButton title="Copy calendar URL to clipboard" @click="copyLink" underline/>
            </div>

            <!-- Calendar settings categories -->

            <div class="settings">
                <div class="title">Settings</div>
                <div class="property__cont">
                    <div class="property"
                    v-for="settingType,index in menuItems()" 
                    :key="`property__${index}`"
                    @click="() => {goToSettings(settingType.routeName)}">
                        <div class="property__card">
                            <div class="property__title">{{settingType.title}}</div>
                            <span class="iconify" 
							data-icon="material-symbols:chevron-right" 
							data-width="26" 
							data-height="26">
							</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Toast from '../Toast.vue';
import BaseButton from '../inputs/BaseButton.vue';

const MENU_ITEMS = [
    {title: "Calendar settings",    routeName: "calendar-settings-main"},
    {title: "Slot templates",       routeName: "calendar-settings-template-menu"},
    {title: "Event hosts",          routeName: "calendar-settings-host-menu"},
    {title: "Event Price Levels",   routeName: "calendar-settings-price-level"},
    {title: "Subscription Packages"},
    {title: "Payment Requisites"},
    {title: "Billing Rules"},
    {title: "Access Management"}
]

export default {
    name: 'MainMenu',
    components: {
        BaseButton,
    },
	computed: {
		calendar(){
			return this.$store.getters.currentCalendar()
		},
		isNewCalendar(){
			return this.$route.params.isNewCalendar
		},
        calendarTitle(){
			if (!this.calendar)
				return ""
            return this.calendar.title
        },
        calendarVisibilityId(){
			if (!this.calendar)
				return 1
            return this.calendar.visibilityLevelId
        }
	},

    // created() {
    //     let uid = this.$route.params.calendarUid


    //     if (this.calendarVisibilityId == -1 || this.isNewCalendar){
    //         CMS.calendar.getByUid(uid).then(calendarData => {
    //             if (calendarData.err){
    //                 throw calendarData.err
    //             }
    //             this.$store.dispatch('set-last-calendar', calendarData)
    //         })
    //     }

    // },
    data() {
        return {
            isOpen: false,
        };
    },

    methods: {
		
		goOut(){
			this.$router.push({name: "calendar-schedule", params: {
				calendarUid: this.$route.params.calendarUid
			}})
		},

        getVisibilityTitle(){
            const titles = [
                'none',
                'private',
                'public',
                'public to all registered users',
                'public to followers only'
            ]

            return titles[this.calendarVisibilityId]
        },

		goToSettings(routeName){
			if (routeName == undefined) return

			this.$router.push({name: routeName, params: {
				calendarUid: this.$route.params.calendarUid
			}})
		},

        goToCalendar(){
            let calendarUid = this.$route.params.calendarUid
            this.$router.push({name:"calendar-schedule", params:{calendarUid: calendarUid}})
        },
        
        copyLink(){
            let calendarUid = this.$route.params.calendarUid
            let link = window.location.origin + "/" + calendarUid

            try {
                const tempTextArea = document.createElement("textarea");
                tempTextArea.value = link;
                document.body.appendChild(tempTextArea);

                tempTextArea.select();
                document.execCommand("copy");
                document.body.removeChild(tempTextArea);
                this.viewToastMessage("URL successfully copied to clipboard", "copy")

                // this.viewToastMessage("Error with URL copy", "error")
            }
            catch (err) {
                this.viewToastMessage("Error with URL copy", "error")
            }
        },

        viewToastMessage(message, type){
            let ComponentClass = Vue.extend(Toast)
            let instance = new ComponentClass({
                propsData: { 
                    duration: 2500,
                    message: message,
                    toastType: type,
                },
            })

            let page = document.getElementsByClassName("menu_calendar_settings")[0]
            instance.$mount() // pass nothing
            page.appendChild(instance.$el)
        },

        menuItems(){
			const calendar = this.calendar
			if (!calendar)
				return []
            const skillLevel = {
				title: `Event ${calendar.settings.ratingTypeName ?? 'Complexity'} Levels`, 
				routeName: "calendar-settings-rating-menu"
			}
            let items = [...MENU_ITEMS]
            items.splice(6, 0, skillLevel)

            return items
        },
    },
};
</script>

<style scoped>

.menu{
    animation: fade .3s;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.created-message{
    border: 1px solid grey;
    padding: 10px;
    border-radius: 5px;
    background: rgb(193, 236, 193);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.copy-link{
    padding: 15px;

}

.copy-link__title {
    color: rgb(0, 108, 229);
    text-decoration: underline;
    user-select: none;
}

.go_to_calendar{
    width: 180px;
    padding: 7px 0;
    font-size: 16px;
}

.property__title{
    user-select: none;
}

.title{
    user-select: none;
}


.property__cont{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.property__card{
    background: rgb(235, 235, 235);
    padding: 8px;
    display: grid;
    grid-template-columns: calc(100% - 10px - 26px) 26px;
    grid-column-gap: 10px;
}

.property__card > *{
    display: flex;
    align-items: center;
}

</style>